const AdminSideMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'User Management',
    name: 'sidebar.user-management',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-2-line',
    children: [
      {
        title: 'User Accounts',
        is_active: false,
        link: {
          name: 'admin.user-accounts'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-shield-user-line'
      }
    ]
  }
]

export default AdminSideMenu
