const UserSideMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'user.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'Maintenance',
    name: 'sidebar.maintenance',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-settings-line',
    children: [
      {
        title: 'Companies',
        is_active: false,
        link: {
          name: 'user.companies'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      },
      {
        title: 'Organizations',
        is_active: false,
        link: {
          name: 'user.organizations'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-scan-2-line'
      },
      {
        title: 'Positions',
        is_active: false,
        link: {
          name: 'user.positions'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-scan-line'
      },
      {
        title: 'Employees',
        is_active: false,
        link: {
          name: 'user.employees'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-contacts-line'
      }
    ]
  }
]

export default UserSideMenu
